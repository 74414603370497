.form__section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.form__content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 0 1rem;
}
.input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.email__label,
.password__label {
  color: white;
  font-size: 14px;
}

.email__input,
.password__input {
  padding: 5px;
  width: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  border-image-slice: 1;
  position: relative;
  background: transparent;
  z-index: 1;
  color: white;
  transition: all 0.3s;
}
.button__wrapper {
  margin: 1rem;
  display: flex;
  justify-content: flex-end;
}
.forgot__password {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  font-size: 13px;
}
.submit__button {
  background-image: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  border-radius: 5px;
  background-color: transparent;
  color: white;
  padding: 8px 16px;
  border: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}
