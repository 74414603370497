@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&family=Montserrat:wght@100;400;600&family=Poppins&family=Ubuntu:wght@300&display=swap');

:root {
  --primary-color: rgb(0, 162, 255);
  --secondary-color: rgb(140, 0, 255);
  --background-color: #130017;
  --dark: #202020;
  --light: #f3f3f3;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

.app {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  font-family: 'poppins', sans-serif;
  width: 1024px;
  min-height: 100vh;
  align-items: center;
  background-color: var(--background-color);
}
.brand {
  width: 25%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.brand a {
  text-decoration: none;
  color: var(--light);
}
.brand span {
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  background-clip: text;
  color: transparent;
}
.nav {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.nav ul {
  margin: 0;
  width: 50%;
  height: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}
.nav-item {
  font-size: 14px;
  text-decoration: none;
  color: var(--light);
  font-weight: 300;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-item:hover {
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  background-clip: text;
  color: transparent;
}
.nav-item:hover::after {
  content: '   ';
  margin-top: 5px;
  height: 2px;
  border-radius: 10px;
  width: 100%;
}
.nav-item::after {
  content: '   ';
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  height: 2px;
  width: 0;
  transition: all 0.5s ease;
}
.nav-item:active {
  transform: scale(0.9);
  font-weight: 600;
}
.button-wrapper {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.signin__button {
  background-image: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  border-radius: 5px;
  background-color: transparent;
  color: white;
  padding: 5px 10px;
  border: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.signin__button:hover {
  transform: scale(1.1);
}
.signup__button {
  padding: 5px 10px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease;
}
.signup__button:hover {
  transform: scale(1.1);
}
.signup__button::before {
  content: '';
  position: absolute;
  top: 1px;
  right: 1px;
  left: 1px;
  bottom: 1px;
  background-color: var(--background-color);
  border-radius: 4px;
  z-index: -1;
}
.signup__button::after {
  content: attr(data);
  font-weight: 600;
  font-size: 12px;
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  background-clip: text;
  color: transparent;
}
.signup__button:hover:before {
  content: '';
  position: absolute;
  top: 1px;
  right: 1px;
  left: 1px;
  bottom: 1px;
  background-color: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  border-radius: 4px;
  z-index: -1;
}
.signup__button:hover::after {
  content: attr(data);
  font-weight: 600;
  font-size: 12px;
  background: var(--light);
  background-clip: text;
  color: transparent;
}
.active {
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  background-clip: text;
  color: transparent;
  font-weight: 600;
}
.active::after {
  content: '   ';
  margin-top: 5px;
  height: 2px;
  border-radius: 10px;
  width: 100%;
}

@media screen {
  @media (max-width: 64em) {
    .app {
      width: 800px;
    }
  }
}
@media screen {
  @media (max-width: 48em) {
    .app {
      width: 550px;
    }
  }
}
@media screen {
  @media (max-width: 35em) {
    .app {
      width: 400px;
    }
  }
}
