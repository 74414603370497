.sign__section {
  width: 100%;
  height: 100vh;
  padding: 0 1rem 4rem 1rem;
}
.sign__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('../Images/Particles2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.sign__wrapper {
  width: 350px;
  height: 400px;
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
  z-index: 1;
  color: var(--light);
  transition: all 0.3s;
}
.sign__wrapper::before {
  content: '  ';
  position: absolute;
  top: 1px;
  right: 1px;
  left: 1px;
  bottom: 1px;
  background-color: var(--background-color);
  border-radius: 4px;
  z-index: -1;
  transition: all 0.3s;
}
.menu__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
}
.signin__wrapper,
.signup__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.sign__wrapper a {
  color: white;
  text-decoration: none;
}
.content__wrapper {
  height: 300px;
}
