.footer {
  width: 100%;
  height: 30vh;
}
.footer-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1rem;
}
.footer-content-wrapper {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-content-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.footer-content-contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
}
.footer-content-content > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.footer-content-content > li {
  text-decoration: none;
}
.footer-content-content li > a {
  padding: 8px;
  background-color: var(--light);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit {
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 30%;
  color: white;
  background: linear-gradient(to bottom right, var(--primary-color), var(--secondary-color));
}
